import React from "react"
import moment from "moment"

import "./animated-tile.scss"

class AnimatedTile extends React.Component {

    render(){
        return (
            <div className={`square ${this.props.align === "right" ? "float-right" :""}`} style={{backgroundImage:`url(${this.props.file})`}}>
                <div className={"hover-top"}>
                    <div className={"content p8 leading-none"}>
                        <p className={"font-montserrat-regular text-white text-xl uppercase"}>{moment.parseZone(this.props.date).format("ddd")}</p>
                        <p className={"font-montserrat-regular text-white text-xl uppercase"}>{moment.parseZone(this.props.date).format("MMMM Do")}</p>
                        <p className={"font-montserrat-extra-bold text-white text-xl"}>{this.props.title}</p>
                    </div>
                </div>
                <div className={"absolute hover-background"}></div>
            </div>
        )
    }

}

export default AnimatedTile
