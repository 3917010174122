import React from "react"
import styles from "./gallery.module.scss"
import Carousel from "../../../carousel/carousel"
import languages from "../../../../lib/languages"

const Gallery = ({ entry }) => {
  const images = entry.images[languages.defaultLangKey].map(item => item.fields.file[languages.defaultLangKey].url)
  return (
    <div className={styles.root}>
      <Carousel>
        {images.map((url, i) => (
          <img src={url} key={i} alt="" />
        ))}
      </Carousel>
    </div>
  )
}

export default Gallery
