import React from "react"
import styles from "./ordered-list.module.scss"
import Paragraph from "../paragraph/paragraph"
import Bold from "../bold/bold"

const OrderedList = ({ children }) => (
  <div className={styles.root}>
    {children.map((child, i) => (
      <div className={styles.row} key={i}>
        <div className={styles.number}>
          <Paragraph children={<Bold children={i + 1 + "."} />} />
        </div>
        {child}
      </div>
    ))}
  </div>
)

export default OrderedList
