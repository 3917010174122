import React from "react"
import { Link } from "gatsby"
import styles from "./artist-locations.module.scss"
import AnimatedTile from "../layout/animated-tile"
import Button from "../button/button"
import Text1 from "../text/text-1"

const ArtistLocations = ({ artistName, locations, locale, ticketsCta }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          <Text1 text={`Watch ${artistName} live`} />
        </div>
        <div className={styles.spacer} />
        <a href={ticketsCta} target="_blank" rel="noopener noreferrer">
          <Button label="BUY TICKETS" type="underline" />
        </a>
      </div>
      <div className={styles.locations}>
        {locations && locations
          .sort((a, b) => new Date(a.date) - new Date(b.date))
          .map(location => (
            <div className={styles.animatedTileContainer} key={location.slug}>
              <Link to={`/${locale}/locations/${location.slug}`}>
                <AnimatedTile title={location.title} date={location.date} file={location.tile.file.url} />
              </Link>
            </div>
          ))}
      </div>
    </div>
  )
}

export default ArtistLocations
