import React from "react"
import Slider from "react-slick"

import RightArrow from "../../images/right-arrow.svg"
import LeftArrow from "../../images/left-arrow.svg"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./carousel.scss"

function SampleNextArrow(props) {

    const { style, onClick } = props

    return (
        <div
            className={"arrow-button"}
            style={{ ...style, display: "block", position:'absolute', bottom:'-40px', right:'0px', zIndex:'5001', cursor:'pointer'}}
            onClick={onClick}
        >
            <img src={RightArrow} alt="next" />
        </div>
    )
}
  
function SamplePrevArrow(props) {

    const { style, onClick } = props

    return (
        <div
            className={"arrow-button"}
            style={{ ...style, display: "block", position:'absolute', bottom:'-40px', left:'0px', zIndex:'5000', cursor:'pointer'}}
            onClick={onClick}
        >
            <img src={LeftArrow} alt="next" />
        </div>
    )
}

class Carousel extends React.Component { 

    render(){

        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        }

        return(
            <Slider {...settings}>
                {this.props.children}
            </Slider>
        )

    }

}

export default Carousel