import React from "react"
import { Link } from "gatsby"
import _ from "lodash"
import styles from "./hyperlink.module.scss"

const Hyperlink = ({ locale, href, children }) => {
  return _.includes(href, "://") ? (
    <a className={styles.root} href={href}>
      {children}
    </a>
  ) : (
    <Link className={styles.root} to={`/${locale}${href}`}>
      {children}
    </Link>
  )
}

export default Hyperlink
