import React from "react"
import styles from "./youtube-video.module.scss"
import languages from "../../../../lib/languages"

const YoutubeVideo = ({ entry }) => (
  <div className={styles.root}>
    <iframe
      className={styles.iframe}
      src={entry.url[languages.defaultLangKey]}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="youtube-video"
    ></iframe>
  </div>
)

export default YoutubeVideo
