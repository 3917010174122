import React from "react"
import styles from "./artist-biography.module.scss"
import RichTextField from "../textfield/rich"
import Text1 from "../text/text-1"
import SocialLink from "./social-link"

const ArtistBiography = ({
  imagePath,
  biography,
  locale,
  instagramUrl,
  instagramCopy,
  spotifyUrl,
  spotifyCopy,
  youtubeUrl,
  youtubeCopy,
  facebookUrl,
  facebookCopy,
  twitterUrl,
  twitterCopy,
  websiteUrl,
  websiteCopy,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <div className={styles.imageWrapper}>
          <img className={styles.image} src={imagePath} alt="" />
        </div>
      </div>
      <div className={styles.contentContainer}>
        <Text1 text="Biography" />
        <div className={styles.spacer} />
        <RichTextField json={biography} locale={locale} />
        <div className={styles.spacer} />
        <SocialLink iconPath={require("../../images/instagram.svg")} url={instagramUrl} copy={instagramCopy} />
        <SocialLink iconPath={require("../../images/spotify.svg")} url={spotifyUrl} copy={spotifyCopy} />
        <SocialLink iconPath={require("../../images/youtube.svg")} url={youtubeUrl} copy={youtubeCopy} />
        <SocialLink iconPath={require("../../images/facebook.svg")} url={facebookUrl} copy={facebookCopy} />
        <SocialLink iconPath={require("../../images/twitter.svg")} url={twitterUrl} copy={twitterCopy} />
        <SocialLink iconPath={require("../../images/website.svg")} url={websiteUrl} copy={websiteCopy} />
      </div>
    </div>
  )
}

export default ArtistBiography
