import React from "react"
import styles from "./video.module.scss"
import languages from "../../../../lib/languages"
import VideoPlayer from "../../../player/video"

const Video = ({ entry }) => (
  <div className={styles.root}>
    <div className={styles.playerContainer}>
      <VideoPlayer
        source={entry.video[languages.defaultLangKey].fields.file[languages.defaultLangKey].url}
        poster={entry.image[languages.defaultLangKey].fields.file[languages.defaultLangKey].url}
      />
    </div>
  </div>
)

export default Video
