import React from "react"
import Text1 from "../text/text-1"
import Thumbnail from "../thumbnail/thumbnail"
import styles from "./artist-recommendations.module.scss"
import { Link } from "gatsby"

const ArtistRecommendations = ({ artists, locale }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Text1 text="Artists you may also like" />
      </div>
      {/* <div className={styles.artists}>
        {artists.map((artist, i) => (
          <Link to={`/${locale}/artists/${artist.slug}`} key={i}>
            <Thumbnail title={artist.title} image={artist.image.file.url} description={artist.description} details={true} />
          </Link>
        ))}
      </div> */}
      <div className={"flex flex-wrap"}>
        {artists.map((artist, i) => (
        <div className={"mx-auto w-full md:w-1/3 px-2"} key={i}>
          <Link to={`/${locale}/artists/${artist.slug}`}>
            <Thumbnail title={artist.title} image={artist.image.file.url} description={artist.description} details={true} />
          </Link>
        </div>
        ))}
      </div>
    </div>
  )
}

export default ArtistRecommendations
