import React from "react"
import styles from "./text-3.module.scss"

const Text3 = ({ text, color }) => (
  <div className={styles.text} style={{ color }}>
    {text}
  </div>
)

export default Text3
