import React from "react"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import languages from "../../lib/languages"

import H1 from "./components/h1/h1"
import H2 from "./components/h2/h2"
import Paragraph from "./components/paragraph/paragraph"
import Bold from "./components/bold/bold"
import Hyperlink from "./components/hyperlink/hyperlink"
import OrderedList from "./components/ordered-list/ordered-list"
import UnorderedList from "./components/unordered-list/unordered-list"
import ListItem from "./components/list-item/list-item"
import YoutubeVideo from "./components/youtube-video/youtube-video"
import Gallery from "./components/gallery/gallery"
import Video from "./components/video/video"

class RichTextField extends React.Component {
  render() {
    const locale = this.props.locale
    const document = this.props.json
    const options = {
      renderText: text => text.split("\n").flatMap((text, i) => [i > 0 && <br key={i} />, text]),
      renderNode: {
        [BLOCKS.HEADING_1]: (_, children) => <H1 children={children} />,
        [BLOCKS.HEADING_2]: (_, children) => <H2 children={children} />,
        [BLOCKS.PARAGRAPH]: (_, children) => <Paragraph children={children} />,
        [INLINES.HYPERLINK]: (node, children) => <Hyperlink locale={locale} href={node.data.uri} children={children} />,
        [BLOCKS.OL_LIST]: (_, children) => <OrderedList children={children} />,
        [BLOCKS.UL_LIST]: (_, children) => <UnorderedList children={children} />,
        [BLOCKS.LIST_ITEM]: (_, children) => <ListItem children={children} />,
        [BLOCKS.EMBEDDED_ENTRY]: node => {
          const contentType = node.data.target.sys.contentType.sys.id
          const entry = node.data.target.fields
          if (contentType === "youtubeVideo") return <YoutubeVideo entry={entry} />
          if (contentType === "gallery") return <Gallery entry={entry} />
          if (contentType === "video") return <Video entry={entry} />
          return <div />
        },
        [BLOCKS.EMBEDDED_ASSET]: node => {
          if (!node.data.target.fields) return <div />
          const file = node.data.target.fields.file[languages.defaultLangKey]
          return file.contentType === "image/jpeg" || file.contentType === "image/png" ? <img src={file.url} alt="" style={{width:"100%"}} /> : <div />
        },
      },
      renderMark: {
        [MARKS.BOLD]: children => <Bold children={children} />,
      },
    }
    return documentToReactComponents(document, options)
  }
}

export default RichTextField
