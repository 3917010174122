import React from "react"
import styles from "./artist-spotify.module.scss"
import Text3 from "../text/text-3"
import Text4 from "../text/text-4"

const ArtistSpotify = ({ artistName, quote, spotifyEmbedUrl }) => {
  return (
    <div className={styles.container}>
      <div className={styles.quoteContainer}>
        <Text3 text={artistName} />
        <div className={styles.spacer} />
        <Text4 text={quote} />
      </div>
      <div className={styles.spotifyContainer}>
        <iframe title="spotify-iframe" src={spotifyEmbedUrl} width="400" height="480" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
      </div>
    </div>
  )
}

export default ArtistSpotify
