import React from "react"
import Text3 from "../text/text-3"
import styles from "./social-link.module.scss"

const SocialLink = ({ iconPath, url, copy }) => {
  if (!iconPath || !url || !copy) return null
  return (
    <a className={styles.container} href={url} target="_blank" rel="noopener noreferrer">
      <div className={styles.iconContainer}>
        <img className={styles.icon} src={iconPath} alt="" />
      </div>
      <div className={styles.copyContainer}>
        <Text3 text={copy} />
      </div>
    </a>
  )
}

export default SocialLink
