import React from "react"
import styles from "./unordered-list.module.scss"

const UnorderedList = ({ children }) => (
  <div className={styles.root}>
    {children.map((child, i) => (
      <div className={styles.row} key={i}>
        <div className={styles.bulletContainer}>
          <img className={styles.bullet} src={require("../../../../images/bullet.svg")} alt="" />
        </div>
        {child}
      </div>
    ))}
  </div>
)

export default UnorderedList
