import React from "react"
import { graphql } from "gatsby"
import { TransitionPortal } from "gatsby-plugin-transition-link"
import PageTransition from "../components/transition/page"
import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"
import ContentHeader from "../components/layout/content-header"
import ArtistLocations from "../components/artist/artist-locations"
import ArtistBiography from "../components/artist/artist-biography"
import ArtistSpotify from "../components/artist/artist-spotify"
import ArtistRecommendations from "../components/artist/artist-recommendations"
import styles from "./artist.module.scss"

class ArtistTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.pageTransition = React.createRef()
  }

  animate() {
    this.pageTransition.current.play()
  }

  render() {
    const locale = this.props.pageContext.locale
    const { artist, related } = this.props.data
    return (
      <Layout theme="light" path={this.props.path} locale={locale} exit={{ length: 1.75, trigger: () => this.animate() }} entry={{ delay: 0.875 }}>
        <SEO
          title={artist.seo.title}
          description={artist.seo.description}
          keywords={artist.seo.keywords && artist.seo.keywords.keywords}
          image={artist.seo.image ? artist.seo.image.file.url : ''}
        />
        <div className={styles.container}>
          <div className={styles.main}>
            <ContentHeader
              title={artist.title}
              description={artist.description}
              imagePath={artist.image && artist.image.file.url}
              videoPath={artist.video && artist.video.file.url}
              playButtonCopy={artist.videoTitle}
              playButtonUrl={artist.youtubeVideo}
            />
            <div className={styles.content}>
              <ArtistLocations artistName={artist.title} locations={artist.locations} locale={locale} ticketsCta={artist.ticketsCta} />
              <ArtistBiography
                imagePath={artist.biographyImage.file.url}
                biography={artist.biography.json}
                locale={locale}
                instagramUrl={artist.instagramUrl}
                instagramCopy={artist.instagramCopy}
                spotifyUrl={artist.spotifyUrl}
                spotifyCopy={artist.spotifyCopy}
                youtubeUrl={artist.youtubeUrl}
                youtubeCopy={artist.youtubeCopy}
                websiteUrl={artist.websiteUrl}
                websiteCopy={artist.websiteCopy}
                facebookUrl={artist.facebookUrl}
                facebookCopy={artist.facebookCopy}
                twitterUrl={artist.twitterUrl}
                twitterCopy={artist.twitterCopy}
              />
              <ArtistSpotify artistName={artist.title} quote={artist.quote} spotifyEmbedUrl={artist.spotifyWidget} />
              <ArtistRecommendations artists={related.edges.map(edge => edge.node)} locale={locale} />
            </div>
          </div>
        </div>
        <TransitionPortal>
          <PageTransition ref={this.pageTransition} />
        </TransitionPortal>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query ArtistPageQuery($slug: String!, $locale: String!) {
    artist: contentfulArtist(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      locations: location {
        ... on ContentfulLocation {
          title
          slug
          date
          tile {
            file {
              url
            }
          }
        }
      }
      title
      slug
      description
      image {
        file {
          url
        }
      }
      videoTitle
      video {
        file {
          url
        }
      }
      biographyImage {
        file {
          url
        }
      }
      biographyImage {
        file {
          url
        }
      }
      biography {
        json
      }
      videoTitle
      youtubeVideo
      ticketsCta
      quote
      instagramUrl
      instagramCopy
      facebookUrl
      facebookCopy
      websiteUrl
      websiteCopy
      twitterUrl
      twitterCopy
      youtubeUrl
      youtubeCopy
      spotifyUrl
      spotifyCopy
      spotifyWidget
      seo {
        title
        description
        keywords {
          keywords
        }
        image {
          file {
            url
          }
        }
      }
    }
    related: allContentfulArtist(limit: 3, filter: { slug: { ne: $slug } }) {
      edges {
        node {
          title
          slug
          description
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
`

export default ArtistTemplate
